<template>
    <div class="positionT0L0">
        <div class="header">
            <div class="headerBox">
                <a-date-picker @change="registerChange" v-model="form.datePicker" class="margin_right20" placeholder="操作日期" style="width: 210px;"/>
                <a-input v-model="form.user" placeholder="请输入操作人" class="margin_right20" style="width: 210px;"/>
                <treeselectIdRadio @treeDataChange="treeDataChange" :isDateShow="isDateShow" :placeholder="placeholderstar" style="width:210px" class="margin_right20"></treeselectIdRadio>
                <treeselectIdRadio2 @treeDataChange="treeDataChangeNew" :isDateShow="isDateShows" :placeholder="placeholderend" style="width:210px"></treeselectIdRadio2>
            </div>
            <div class="headerBtn">
                <a-button class="margin_right20" type="primary" @click="queryClick">查询</a-button>
<!--                <a-button class="margin_right20" type="primary" @click="resetClick">重置</a-button>-->
                <a-button type="primary" @click="queryClick">刷新</a-button>
            </div>
        </div>

        <div class="contant-box">
            <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.id" :loading="tableLoading" :pagination="false">
                <template slot="status" slot-scope="text, record">
                    <span v-if="record.status == 0">未完成</span>
                    <span v-else>已完成</span>
                </template>
                <span slot="action" slot-scope="text, record">
                    <a @click="downloadClick(record)" v-if="record.status != 0">下载</a>
                    <a v-else></a>
                </span>
            </a-table>
        </div>

        <!-- 分页功能 -->
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="tableList.length>0"/>

        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button @click="$router.go(-1)">返回</a-button>
        </footer-tool-bar>

        <a-modal v-model="visible" title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button @click="visible = false" class="margin_right20">取消</a-button>
                    <download-excel
                        :data="exportList"
                        :fields="tableDataName"
                        name="用户归属划分记录.xls">
                        <a-button type="primary" @click="visible = false">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p>
                <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 10px">确定要下载此数据吗？</span>
            </p>
        </a-modal>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import {BatchList, UserListForBatch} from "@/request/api/userManage";
import treeselectIdRadio from "@/components/channel/treeselectIdRadio"
import treeselectIdRadio2 from "@/components/channel/treeselectIdRadio"
export default {
    mixins: [baseMixin],
    components: {
        MyPagination,
        FooterToolBar,
        treeselectIdRadio,
        treeselectIdRadio2
    },
    created() {
        this.getTagList()
    },
    data() {
        return {
            visible:false,
            isDateShow:true,
            isDateShows:false,
            tableLoading: false,
            form:{
                startTime:'',
                datePicker:'',
                user:'',
                before:'',
                after:'',
            },
            columns: [
                {
                    title: "序号",
                    dataIndex: "id",
                    key: "id",
                },
                {
                    title: "变更前渠道",
                    dataIndex: "before",
                    key: "before",
                },
                {
                    title: "变更后渠道",
                    dataIndex: "after",
                    key: "after",
                },
                {
                    title: "变更数量",
                    dataIndex: "count",
                    key: "count",
                },
                {
                    title: "操作人",
                    dataIndex: "admin",
                    key: "admin",
                },
                {
                    title: "操作时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "批次状态",
                    dataIndex: "status",
                    key: "status",
                    scopedSlots: {customRender: "status"},
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "80px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0,// 列表的总条数
            placeholderstar:'请选择变更前渠道',
            placeholderend:'请选择变更后渠道',
            exportList:[],
            tableDataName: {
                "用户姓名": "nickName",
                "用户手机": "userName",
                "注册来源": {
                    field: "source",
                    callback: value => {
                        return value == 1 ? 'APP_Android' : value == 2 ? 'APP_iOS' : value == 3 ? '直播_视频号' : value == 4 ? '直播_抖音' : value == 5 ? '直播预约小程序' : value == 6 ? 'H5阅读大使推广二维码' : value == 6 ? 'H5阅读大使推广二维码' : value == 7 ? 'H5大使推广二维码' : value == 8 ? '人工操作' : value == 9 ? '历史注册' : value == 99 ? '其他' : '--'
                    }
                },
                "注册时间": "createTime",
            },
        };
    },
    methods: {
        // 搜索·变更前渠道
        treeDataChange(val){
            this.form.before = val
        },

        // 搜索·变更后渠道
        treeDataChangeNew(val){
            this.form.after = val
        },

        // 搜索 - 修改时间
        registerChange(date, dateString) {
            if (dateString) {
                this.form.startTime = dateString;
            } else {
                this.form.startTime = "";
            }
        },

        // 点击搜索按钮
        queryClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.getTagList();
        },

        // 点击重置
        resetClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.isDateShow = false
            this.isDateShows = false
            this.form.startTime = ''
            this.form.datePicker = ''
            this.form.user = ''
            this.form.before = ''
            this.form.after = ''
            this.getTagList()
        },

        // 点击下载
        downloadClick(record){
            UserListForBatch({
                batchId: record.id
            }).then((res) => {
                if (res.code === 200) {
                    this.visible = true
                    this.exportList = res.data
                }
            });
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            let self = this
            this.tableLoading = true;
            BatchList({
                batchDate:self.form.startTime,
                adminer:self.form.user,
                beforeAgentNo:self.form.before == undefined ? '' : self.form.before,
                afterAgentNo:self.form.after == undefined ? '' : self.form.after,
                pageNo: self.pageNo,
                pageSize: self.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    self.tableList = res.data.list;
                    self.count = res.data.count;
                }
                self.tableLoading = false;
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .ant-input{
    height: 36px !important;
}
/deep/ .ant-select-selection--single{
    height: 36px !important;
}
.header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .headerBox{
        display: flex;
    }
    .headerBtn{
        display: flex;
        align-items: center;
    }
}
.contant-box{
    margin-bottom: 20px;
}
/deep/.pagination{
    margin-right: 0;
}
</style>
