<template>
    <div class="treeselect">
        <treeselect ref="deptPost" v-model="treeselectArr" :placeholder="placeholder" :options="options" :normalizer="normalizer" :searchable="false" @input="treeselectChange"/>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {AgentTree} from "@/request/api/userManage";
export default {
    components: {Treeselect},
    props: {
        isDateShow:{
            type:Boolean,
            default:true,
        },
        placeholder:{
            type:String,
            default:'请选择渠道',
        }
    },
    data() {
        return {
            depts:'',
            options:[],
            treeselectArr:null,
            normalizer(node) {
                return {
                    id: node.agentNo,
                    label: node.agentName,
                    children: node.childs,
                }
            },
        };
    },
    watch: {
        isDateShow: function (val) {
            this.treeselectChange()
            this.treeselectArr = null
        }
    },
    created() {
        AgentTree({}).then((res) => {
            if (res.code === 200) {
                this.options = res.data.lists
            }
        });
    },
    methods: {
        treeselectChange(value){
            if(value != undefined){
                let deptsStr = ''
                this.$refs.deptPost.selectedNodes.forEach(dept => {
                    deptsStr = dept.label
                })
                this.$emit('treeDataChange', value,deptsStr);
            }else{
                this.$emit('treeDataChange', value);
            }
        },
    }
};
</script>
<style lang="less" scoped>

</style>
